<template>
  <a
    :href="link"
    class="v-list-item v-list-item--link theme--dark"
    tabindex="0"
    target="_blank"
  ><div class="v-list-item__icon">
     <i
       aria-hidden="true"
       class="v-icon notranslate mdi mdi-link-variant theme--dark"
     />
   </div>
    <div class="v-list-item__content">
      <div class="v-list-item__title">{{ label }}</div>
      <div class="v-list-item__subtitle" /></div></a>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    }
  }
}
</script>

<style></style>
