<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-breakpoint="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <v-list :key="renderKey" expand nav>
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
      <template v-for="(item, i) in items">
        <template v-if="visible(item)">
          <base-item-group
            v-if="item.children"
            :key="`group-${i}`"
            :item="item"
          >
            <!--  -->
          </base-item-group>

          <base-item v-else :key="`item-${i}`" :item="item" />
        </template>
      </template>

      <external-link
        v-if="is_staff()"
        :label="$t('admin-panel.header')"
        :link="`${axios.defaults.baseURL}/admin`"
      />

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapState } from 'vuex';
import ExternalLink from './ExternalLink.vue';
import store from '@/store';

export default {
  name: 'DashboardCoreDrawer',
  components: { ExternalLink },
  props: {
    expandOnHover: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    renderKey: 0,
    items: [
      {
        icon: 'mdi-home',
        title: 'home.header',
        to: '/home',
        permission: 'api.view_order'
      },
      {
        icon: 'mdi-cube-outline',
        title: 'orders.header',
        to: '/dashboard/orders',
        permission: 'api.view_order'
      },
      {
        icon: 'mdi-account-tie-voice',
        title: 'customers.header',
        to: '/dashboard/customer',
        permission: 'api.view_customer'
      },
      {
        icon: 'mdi-cog',
        title: 'settings.header',
        permission: 'api.view_machine',
        group: '/dashboard',
        children: [
          {
            icon: 'mdi-printer',
            title: 'machines.header',
            to: 'machines',
            permission: 'api.view_machine'
          },
          {
            icon: 'mdi-axe',
            title: 'tools.header',
            to: 'tools',
            permission: 'api.view_tool'
          }
        ]
      },
      {
        icon: 'mdi-abacus',
        title: 'production-plan.header',
        to: '/dashboard/plans',
        permission: 'api.view_planitem'
      },
      {
        icon: 'mdi-lightning-bolt',
        title: 'operator-view.header',
        to: '/operator/panel',
        permission: 'api.view_planitem'
      },
      {
        icon: 'mdi-library-shelves',
        title: 'warehouse.header',
        to: '/dashboard/warehouse',
        permission: 'api.view_warehouseitem'
      },
      {
        icon: 'mdi-paper-roll',
        title: 'materials.header',
        to: '/dashboard/materials',
        permission: 'api.view_materialpart'
      },
      {
        icon: 'mdi-truck-delivery-outline',
        title: 'shipment.header',
        to: '/dashboard/shipment',
        permission: 'api.view_shipment'
      }
    ]
  }),

  computed: {
    ...mapState(['barColor', 'barImage', 'userVersion']),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit('SET_DRAWER', val);
      }
    },
    computedItems() {
      let c = this.items.map(this.mapItem);
      console.log('computed: ', c);
      return c;
    },
    role() {
      return 0;
    },
    profile() {
      return {
        avatar: true,
        title: this.$t('avatar')
      };
    }
  },
  watch: {
    userVersion(oldv, newv) {
      console.log('User re-logged: ', oldv, newv);
      this.renderKey++;
    }
  },

  mounted() {},

  methods: {
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title)
      };
    },
    is_staff() {
      if (!store.state.session.user) return false;
      return store.state.session.user.is_staff;
    },
    visible(item) {
      if (item.permission == null) {
        return true;
      }
      if (this.$store.state.session.user == null) {
        return false;
      }
      // console.log(this.$store.state.session.user.permissions)
      return this.$store.state.session.user.permissions.includes(
        item.permission
      );
    }
  }
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px
</style>
